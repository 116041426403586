import React from 'react';
import Card from 'react-bootstrap/Card';
import AccordionItemTitle from './accordionItemTitle';
import AccordionItemContent from './accordionItemContent';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { highlightText, checkWetherShouldAppear } from '../utils';
import DisappearingBrick from './disappearingBrick.tsx';

const CustomAccordion = (props) => {
  const { expand, onClick } = props;

  let shouldBodyAppear = props.textFilter.length > 2 && checkWetherShouldAppear({ contentBody: props.item.contentBody }, props.textFilter)
  return (
    <div>
    <DisappearingBrick size="5" />
    <Card className="shadow mb-3 curriculum-item background-transparent">
      <Card.Header className={expand ? 'title is-expanded background-transparent' : 'title background-transparent'} onClick={onClick}>
        <AccordionItemTitle key={props.item} item={props.item} mobile={props.mobile} textFilter={props.textFilter} formCheckLockclick={props.formCheckLockclick}></AccordionItemTitle>
      </Card.Header>

      <div>
        {props.item.contentBody !== "" ?
          <Card.Body className={expand || shouldBodyAppear ? 'content is-expanded py-0 background-transparent' : 'background-transparent content py-0'} >
            <AccordionItemContent key={props.item.id} contentBody={props.item.contentBody} textFilter={props.textFilter} />
          </Card.Body>
          : null}
        {props.item.contentTechs !== "" ?
          <Card.Footer className="background-transparent">
            <Row>
              <Col xs="auto" className="pe-0"><i className="fa-solid fa-keyboard"></i> </Col> <Col>{highlightText(props.item.contentTechs, props.textFilter)}</Col>
            </Row>
          </Card.Footer>
          : null}
      </div>



    </Card>
    </div>
  );
}


export default CustomAccordion;