const lastExpStartDate = new Date('2020-03-23')
const now = new Date()
const lastExpY = now.getFullYear() - lastExpStartDate.getFullYear()
const lastExpM = now.getMonth() - lastExpStartDate.getMonth()
const yearAndMonths = Math.round((lastExpM/10 + lastExpY) * 10) / 10

let data = [
  {
    id: 1,
    startDate: "2020-03-20",
    endDate: "",
    type: "work",
    typeTitle: "Work",
    date: "2020-Now",
    mainTitle: "Data Engineer at LCL",
    subtitle: "Various missions involving IT and bigdata expertise.",
    location: "Paris",
    duration: <span>{yearAndMonths} years</span>,
    contentBody: "Within a core data team, implementation of new CI/CD pipelines on Gitlab for the projects of other squads.|Industrialization of an API used for Hbase data exposure on a Crédit Agricole platform called \"Kubernative\". Technologies used: Gitlab, ArgoCD, Kubernetes, Vault, Elastic|Development of a new data processing module in the internal framework: \"Hbase to Hadoop\". It allows to be able to query rowkeys and HBase qualifiers in an optimized way using spark-sql.|Design and development of API monitoring: consolidation of logs from several servers in kafka, logstash ingestion in elasticsearch and construction of a Kibana dashboard.|Design and development of a solution for aggregating, storing and restoring a large volume of data. Batch and real-time ingestion. Hbase storage, restitution by API (search for keywords, filters, ...).|Administration, animation and support of users of an Dataiku AI platform. Development of an industrialization procedure on \"automation\" nodes. Industrialization of projects on Dataiku.|Development of SQL/Spark processing for preparation, analysis and data enrichment.|Industrialization of AI projects oriented computer vision, in particular classification and reading of documents. Optimization of CPU/GPU/Memory performance.|Resolution of production incidents, implementation of solutions to guarantee the quality of the run.",
    contentTechs: "Cloudera • Java • Spark • Hbase • Kafka • SQL • Python • Unix • Bash • Dataiku • Scala • Elastic • Kibana • Gitlab • ArgoCD • Kubernetes • Vault • Teradata",
    expanded: true,
    imgName: "logo_lcl.svg"
  },
  {
    id: 2,
    startDate: "2019-09-20",
    endDate: "2020-01-20",
    type: "work",
    typeTitle: "Work",
    date: "2019-2020",
    mainTitle: "End-of-studies internship at LCL",
    subtitle: "Developments of a \"computer vision\" solution and a Docker microservice.",
    location: "Paris",
    duration: "5 months",
    contentBody: "Development of a solution for classifying and reading supporting documents.|Development of a micro-service for controlling the proper functioning of scanners in LCL agencies.",
    contentTechs: "Python (Numpy, Pandas, Scikit-learn, Nginx, Flask) • Tensorflow • Docker • OpenCV",
    expanded: false,
    imgName: "logo_lcl.svg"
  },

  {
    id: 4,
    startDate: "2018-09-20",
    endDate: "2019-06-20",
    type: "edu",
    typeTitle: "Education",
    date: "2018-2019",
    mainTitle: "Student at the University of Birmingham",
    subtitle: "With high honours. Notable Courses: Machine Learning, Artificial Intelligence",
    location: "Birmingham",
    duration: "1 year",
    contentBody: "",
    contentTechs: "",
    expanded: false,
    imgName: "logo_uob.png"
  },
  {
    id: 3,
    startDate: "2018-06-10",
    endDate: "2018-09-10",
    type: "work",
    typeTitle: "Work",
    date: "2018",
    mainTitle: "Internship at Artal Technologies",
    subtitle: "Full stack development of a web application",
    location: "Toulouse",
    duration: "3 months",
    contentBody: "Front/back development of a web application intended for communities of municipalities, within an agile team (about 10 people). Daily reports, contacts with the client to determine the need.",
    contentTechs: "AngularJS • Java • Spring • Hibernate • Maven • Génération de pdfs avec Jasper",
    expanded: false,
    imgName: "logo_artal.png"
  },
  {
    id: 5,
    startDate: "2016-09-01",
    endDate: "2019-09-01",
    type: "edu",
    typeTitle: "Education",
    date: "2016-2019",
    mainTitle: "Engineering student at ENSEEIHT",
    subtitle: "Computational Sciences Department (formerly IMA)",
    location: "Toulouse",
    duration: "3 years",
    contentBody: "You can find the whole program here: https://www.enseeiht.fr/fr/formation/formation-ingenieur/departement-sn.html",
    contentTechs: "Big Data • Artificial Intelligence • Machine Learning • Connected Objects (IoT) • Networks • Software Development • Cloud Computing • High Performance Computing • C • Java • Git",
    expanded: false,
    imgName: "logo_enseeiht.png"
  },
  {
    id: 6,
    startDate: "2014-09-01",
    endDate: "2016-07-01",
    type: "edu",
    typeTitle: "Education",
    date: "2014-2016",
    mainTitle: "Preparatory classes MPSI/MP",
    subtitle: "Intensive training in Mathematics, Physics and Engineering Sciences.",
    location: "Lorient",
    duration: "2 years",
    contentBody: "",
    contentTechs: "",
    expanded: false,
    imgName: ""
  },
  {
    id: 15,
    startDate: "2014-06-01",
    endDate: "",
    type: "edu",
    typeTitle: "Education",
    date: "2014",
    mainTitle: "Scientific baccalaureate",
    subtitle: "With honors",
    location: "Ploërmel",
    duration: "",
    contentBody: "",
    contentTechs: "",
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 7,
    startDate: "2018-11-01",
    endDate: "2018-12-10",
    type: "proj",
    typeTitle: "Project",
    date: "2018",
    mainTitle: "Machine Learning - California Home Prices",
    subtitle: "California Home Price Prediction from a Data Set",
    location: "Birmingham",
    duration: "2 months",
    contentBody: "",
    contentTechs: "Python • Numpy • Pandas • Scikit-learn", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 8,
    startDate: "2022-06-01",
    endDate: "2022-06-01",
    type: "cert",
    typeTitle: "Certificate",
    date: "2022",
    mainTitle: "3rd place in an individual code contest",
    subtitle: "Crédit Agricole / Isograd ”Dev'Week Crédit Agricole 2022”. More than 100 participants from across the group as well as from other companies.",
    location: "Paris",
    duration: "2 hours",
    contentBody: "",
    contentTechs: "Python", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 9,
    startDate: "2021-12-01",
    endDate: "2022-04-01",
    type: "cert",
    typeTitle: "Certificate",
    date: "2022",
    mainTitle: "DataScientest: Data engineer program",
    subtitle: "Training on different bigdata technologies.",
    location: "Paris",
    duration: "4 months",
    contentBody: "",
    contentTechs: "ElasticSearch • Hadoop • Hive • Hbase • Kafka • Mongodb • Neo4j • Pyspark • Spark-streaming • SQL", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 10,
    startDate: "2021-10-01",
    endDate: "2021-11-01",
    type: "cert",
    typeTitle: "Certificate",
    date: "2021",
    mainTitle: "Dataiku certificates",
    subtitle: "Dataiku \"advanced designer\" + \"ML Practitioner\" certificates",
    location: "Paris",
    duration: "",
    contentBody: "",
    contentTechs: "", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 11,
    startDate: "2015-11-01",
    endDate: "2016-06-01",
    type: "proj",
    typeTitle: "Project",
    date: "2015-2016",
    mainTitle: "TIPE preparatory project: Voronoi diagrams",
    subtitle: "Implementation of an algorithm and extension from the plane to the sphere",
    location: "Lorient",
    duration: "6 months",
    contentBody: "Applying code to find less densely populated areas of France, using a dataset of cities in France. Since then, I've created a demo site for this project: https:// voronoi.jlhervy.com/",
    contentTechs: "Python", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 12,
    startDate: "2017-10-01",
    endDate: "2018-01-20",
    type: "proj",
    typeTitle: "Project",
    date: "2018",
    mainTitle: "Several image processing jobs",
    subtitle: "15 mini data processing projects, especially images.",
    location: "Toulouse",
    duration: "6 months",
    contentBody: "ACP, Bayesian Classification, Shape Estimation, Detection and Counting of Objects, Segmentation by Markov Fields, Image Restoration",
    contentTechs: "Matlab", 
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 13,
    startDate: "2018-02-01",
    endDate: "2018-06-01",
    type: "proj",
    typeTitle: "Project",
    date: "2018",
    mainTitle: "Distributed Execution of Map-Reduce-like Applications",
    subtitle: "Development of a Hadoop framework ”from scratch”",
    location: "Toulouse",
    duration: "5 months",
    contentBody: "Development of an HDFS type file system, a namenode, a datanode. Development of advanced features such as high availability.",
    contentTechs: "Java (Sockets, Semaphores, RMI), bash",
    expanded: false,
    imgName: ""
  }
  ,
  {
    id: 14,
    startDate: "2017-11-01",
    endDate: "2018-02-01",
    type: "proj",
    typeTitle: "Project",
    date: "2017",
    mainTitle: "Multiplayer Brick Breaker",
    subtitle: "Developing a video game using the agile Scrum method",
    location: "Toulouse",
    duration: "3 months",
    contentBody: "",
    contentTechs: "Java (Slick2D) • Trello", 
    expanded: false,
    imgName: ""
  },
  {
    id: 16,
    startDate: "2022-10-15",
    endDate: "2022-10-16",
    type: "proj",
    typeTitle: "Project",
    date: "2022",
    mainTitle: "Development of 2 websites : (cv|voronoi).jlhervy.com",
    subtitle: "Hosting on Google Cloud.",
    location: "Paris",
    duration: "",
    contentBody: "",
    contentTechs: "Javascript • React • Python • Cloud • GCP",
    expanded: false,
    imgName: ""
  },
  {
    id: 17,
    startDate: "2022-10-20",
    endDate: "2022-10-21",
    type: "cert",
    typeTitle: "Certificate",
    date: "2022",
    mainTitle: "Google Cloud Training (Coursera Certificate)",
    subtitle: "\"Google Cloud Fundamentals: Core Infrastructure\"",
    location: "Paris",
    duration: "",
    contentBody: "",
    contentTechs: "",
    expanded: false,
    imgName: ""
  }
]

export default data;