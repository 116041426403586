let data = {
    techs: {
        langages: ['JAVA',
            'Scala',
            'Python',
            'Bash',
            'SQL',
            'C'],
        web: ["HTML/CSS",
            "javascript",
            "AngularJS/Vue/React",
            "Django/Flask/Fastapi..."],
        bigdata: ["Spark",
            "Hadoop",
            "MapReduce",
            "Hbase",
            "Kafka",
            "Hive",
            "Cloudera",
            "ElasticSearch",
            "Neo4j"],
        others: ["Docker",
            "Git",
            "Unix",
            "Teradata",
            "Google Cloud"],
        dataScience: ["Deep Learning",
        "Computer vision",
        "Predictive analysis",
        "Tensorflow/Pytorch",
        "Scikit-learn"],
    },
    langages: [
        "English: Good speaking level, very good written level. (TOEIC 985/990)",
        "French: Native"
    ],
    qualities: [
        'Autonomous',
        'Proactive',
        'Efficient',
        'Taste for challenge',
        'Taste for improvement and change',
        '"Bias for action"'
    ],
    passions: [
        "Music, piano",
        "Sport (swimming, running)",
        "Chess",
        "Reading",
        "Science",
        "Technology"
    ]
}

export default data;