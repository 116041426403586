import "./controls.scss"
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';

import Card from 'react-bootstrap/Card';
import { ButtonGroup } from "react-bootstrap";

const Controls = (props) => {

  const [t, _] = useTranslation();
  const [show, setShow] = useState(true)
  const [expanded, setExpanded] = useState(false)

  const searchBarRef = useRef();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <dl>
        <dt>{t('controls-expand')} / {t('controls-fold')}</dt>
        <dd>{t('controls-expand-fold-help')}</dd>

        <dt>{t('controls-filters')}</dt>
        <dd>{t('controls-filters-help')}</dd>
      </dl>

    </Tooltip>
  );

  return ((props.mobile) ?
    <Card id="card-controls" className="mb-3 background-transparent">
      <Card.Body className="p-3 shadow background-transparent">
        <Card.Title className="background-transparent">
          <Row>
            <Col xs="auto">
              <Button size="sm" className="mb-1 me-2" onClick={() => setShow(!show)}> {show ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
              </Button>
              <span className="mt-1">
                {t('controls-title')}
              </span>
            </Col>
            <Col className="px-0">
              <ButtonGroup className="d-flex">
                <Button variant="primary" onClick={props.handlePrint} size="sm" className="flex-fill">{t('controls-export')} </Button>
              </ButtonGroup>
            </Col>
            <Col xs={"auto"} className="d-flex ">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={renderTooltip}
              >
                <i className="fa-solid fa-circle-info pt-1 " ></i>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Title>
        {show ?
          <Container fluid={true}>
            <Row  >
              <Col className="px-0">

                <ButtonGroup className="d-flex">
                  <Button variant="primary" className="flex-fill" size="sm" onClick={() => { props.toggleExpand(!expanded); setExpanded(!expanded); }}>{expanded ? t('controls-fold') : t('controls-expand')}</Button>
                  {/* <Button size="sm" onClick={() => props.setFormCheckLockclick(!props.formCheckLockclick)} onAnimationEnd={() => { props.setAnimateLock(false) }} className={props.animateLock ? 'notice-me ms-2 flex-fill  me-2 me-md-0' : 'ms-2 flex-fill '} >  {t('controls-lock')} <i className="fa-solid fa-arrow-pointer"></i> {props.formCheckLockclick ? <i className="fa-solid fa-lock"></i> : <i className="fa-solid fa-lock-open"></i>}
                  </Button> */}
                </ButtonGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col className="px-0 pe-md-2" >
                <ButtonGroup className="d-flex">
                  <Button size="sm" className="mt-2 flex-fill" onClick={() => props.setFormCheckGroupdomain(!props.formCheckGroupdomain)}>{t('controls-groupby')} {props.formCheckGroupdomain ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-xmark"></i>}</Button>
                  <Button size="sm" className="ms-2 mt-2 flex-fill" onClick={() => props.setFormSort(!props.formSort)}>{t('controls-sort')} {props.formSort ? <i className="fa-solid fa-arrow-down-wide-short"></i> : <i className="fa-solid fa-arrow-down-short-wide"></i>}</Button>
                </ButtonGroup>
              </Col>
            </Row> */}

            <Row>
              <Col xs={12} className="px-0">
                <Form.Control size="sm" ref={searchBarRef} className="mt-2" type="text" placeholder={t('controls-placeholder')} onChange={(event) => props.setTextFilter(event.target.value)} />
              </Col>

            </Row>
            <Row className="d-flex">
              <Col xs={"auto"} className="mt-2 px-1">{props.nbBlocks} {t('exportNbElements')}</Col>
              <Col xs={"auto"} className="pe-0 d-flex flex-fill">
                <Button size="sm" className="mt-2 flex-fill" onClick={() => { props.setTextFilter(''); searchBarRef.current.value = "" }}>{t('controls-reset')}</Button>
              </Col>

            </Row>
          </Container>
          : null}
      </Card.Body>
    </Card >


    :


    <Card id="card-controls" className="mb-3 background-transparent">
      <Card.Body className="p-3 shadow background-transparent">
        <Card.Title>
          <Row>
            <Col>
              <Row>
                <Col md="auto">
                  <Button size="sm" className="mb-1 me-2" onClick={() => setShow(!show)}> {show ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                  </Button>
                  <span className="mt-1">
                    {t('controls-title')}
                  </span>
                </Col>
                <Col>
                  <ButtonGroup className="">
                    <Button variant="primary" onClick={props.handlePrint} size="sm" >{t('controls-export')} {props.nbBlocks} {t('exportNbElements')}</Button>
                  </ButtonGroup>
                </Col>
                <Col xs={"auto"} className="d-flex pt-1 ps-0">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 200 }}
                    overlay={renderTooltip}
                  >
                    <i className="fa-solid fa-circle-info ms-auto " ></i>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Title>
        {show ?
          <Container fluid={true}>
            <Row>
              <Col xs={12} className="">
                <Row className="d-flex px-0">
                  <Col md={"auto"} className="px-0 flex-fill">
                    <ButtonGroup className="d-flex">
                      <Button variant="primary" className="flex-fill" size="sm" onClick={() => { props.toggleExpand(!expanded); setExpanded(!expanded); }}>{expanded ? t('controls-fold') : t('controls-expand')}</Button>
                      {/* <Button size="sm" onClick={() => props.setFormCheckLockclick(!props.formCheckLockclick)} onAnimationEnd={() => { props.setAnimateLock(false) }} className={props.animateLock ? 'notice-me ms-2 flex-fill ' : 'ms-2 flex-fill '} >  {t('controls-lock')} <i className="fa-solid fa-arrow-pointer"></i> {props.formCheckLockclick ? <i className="fa-solid fa-lock"></i> : <i className="fa-solid fa-lock-open"></i>}
                      </Button> */}
                      {/* <Button size="sm" className="ms-2 mt-0 flex-fill" onClick={() => props.setFormCheckGroupdomain(!props.formCheckGroupdomain)}>{t('controls-groupby')} {props.formCheckGroupdomain ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-xmark"></i>}</Button> */}
                      {/* <Button size="sm" className="ms-2  flex-fill" onClick={() => props.setFormSort(!props.formSort)}>{t('controls-sort')} {props.formSort ? <i className="fa-solid fa-arrow-down-wide-short"></i> : <i className="fa-solid fa-arrow-down-short-wide"></i>}</Button> */}
                    </ButtonGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} lg={6} md={6} sm={7} className="px-0 flex-fill">
                    <Form.Control size="sm" ref={searchBarRef} className="mt-2" type="text" placeholder={t('controls-placeholder')} onChange={(event) => props.setTextFilter(event.target.value)} />
                  </Col>
                  <Col xs={12} md={"auto"} className="d-flex pe-0">
                    <Button size="sm" className="mt-2 me-auto flex-fill" onClick={() => { props.setTextFilter(''); searchBarRef.current.value = "" }}>{t('controls-reset')}</Button>
                  </Col>
                  {/* <Col lg={1} xl={3} /> */}
                </Row>
              </Col>
              <Col md={"auto"} ></Col>
            </Row>
          </Container>
          : null}
      </Card.Body>
    </Card>

  )
}
export default Controls;