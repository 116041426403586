const lastExpStartDate = new Date('2020-03-23')
const now = new Date()
const lastExpY = now.getFullYear() - lastExpStartDate.getFullYear()
const lastExpM = now.getMonth() - lastExpStartDate.getMonth()
const yearAndMonths = Math.round((lastExpM/10 + lastExpY) * 10) / 10
let data = [{
  id: 1,
  startDate: "2020-03-20",
  endDate: "",
  type: "work",
  typeTitle: "Travail",
  date: "2020-Ajd.",
  mainTitle: "Data Engineer chez LCL",
  subtitle: "Diverses missions faisant intervenir de l'expertise informatique et bigdata.",
  location: "Paris",
  duration: <span>{yearAndMonths} ans</span>,
  contentBody: "Au sein d'une squad \"Socles DATA\", mise en place de nouveaux pipelines CI/CD sur Gitlab pour les projets des autres squads.| Industrialisation de traitements d'exposition de données Hbase sur une plateforme Crédit Agricole nommée \"Kubernative\". Technologies utilisées : Gitlab, ArgoCD, Kubernetes, Vault, Elastic.|Développement d'un nouveau module de traitement de données dans le framework interne : \"Hbase to Hadoop\". Permet de requêter sur les rowkeys et les qualifiers HBase de manière optimisée en utilisant spark-sql.|Conception et développement d'un monitoring d'API : regroupement des logs de plusieurs serveurs dans kafka, ingestion logstash dans elasticsearch et construction d'un dashboard Kibana.|Conception et développement d'une solution d'agrégation, de stockage et de restitution d'un grand volume de données. Ingestion par batch et temps réel. Stockage Hbase, restitution par API (recherche de mots clefs, filtres, ...).|Administration, animation et accompagnement des utilisateurs d'une plateforme IA Dataiku. Développement d'une procédure d'industrialisation sur les noeuds \"automation\". Industrialisation de projets sur Dataiku.|Développement de traitements SQL/Spark de préparation, d'analyse et d'enrichissement de données.|Industrialisation de projets IA orientés computer vision, en particulier classification et lecture de documents. Optimisation des performances CPU/GPU/Mémoire.|Résolution d'incidents de production, mise en place de solutions pour garantir la qualité du run.",
  contentTechs: "Cloudera • Java • Spark • Hbase • Kafka • SQL • Python • Unix • Bash • Dataiku • Scala • Elastic • Kibana • Gitlab • ArgoCD • Kubernetes • Vault • Teradata",
  expanded: true,
  imgName: "logo_lcl.svg"
},
{
  id: 2,
  startDate: "2019-09-20",
  endDate: "2020-01-20",
  type: "work",
  typeTitle: "Travail",
  date: "2019-2020",
  mainTitle: "Stage de fin d'études chez LCL",
  subtitle: "Développements d'une solution \"computer vision\" et d'un microservice Docker.",
  location: "Paris",
  duration: "5 mois",
  contentBody: "Développement d'une solution de classification et de lecture de pièces justificatives (RADLAD).|Développement d'un micro-service de contrôle du bon fonctionnement des scanners dans les agences LCL.",
  contentTechs: "Python (Numpy, Pandas, Scikit-learn, Nginx, Flask) • Tensorflow • Docker • OpenCV",
  expanded: false,
  imgName: "logo_lcl.svg"
},

{
  id: 4,
  startDate: "2018-09-20",
  endDate: "2019-06-20",
  type: "edu",
  typeTitle: "Éducation",
  date: "2018-2019",
  mainTitle: "Étudiant à l'Université de Birmingham",
  subtitle: "Mention très bien. Cours notables : Machine learning, Intelligence artificielle",
  location: "Birmingham",
  duration: "1 an",
  contentBody: "",
  contentTechs: "",
  expanded: false,
  imgName: "logo_uob.png"
},
{
  id: 3,
  startDate: "2018-06-10",
  endDate: "2018-09-10",
  type: "work",
  typeTitle: "Travail",
  date: "2018",
  mainTitle: "Stage chez Artal Technologies",
  subtitle: "Développement full stack d'une application Web",
  location: "Toulouse",
  duration: "3 mois",
  contentBody: "Développement front/back d'une application web destinée aux communautés de communes, au sein d'une équipe agile (10 personnes environ). Dailys quotidiens, contacts avec le client afin de déterminer le besoin.",
  contentTechs: "AngularJS • Java • Spring • Hibernate • Maven • Génération de pdfs avec Jasper",
  expanded: false,
  imgName: "logo_artal.png"
},
{
  id: 5,
  startDate: "2016-09-01",
  endDate: "2019-09-01",
  type: "edu",
  typeTitle: "Éducation",
  date: "2016-2019",
  mainTitle: "Élève Ingénieur à l'ENSEEIHT",
  subtitle: "Département sciences du numériques (anciennement IMA)",
  location: "Toulouse",
  duration: "3 ans",
  contentBody: "Vous pouvez retrouver tout le programme ici : https://www.enseeiht.fr/fr/formation/formation-ingenieur/departement-sn.html",
  contentTechs: "Big Data • Intelligence artificielle • Machine Learning • Objets connectés (IoT) • Réseaux • Développement Logiciel • Cloud computing • Calcul Haute performance • C • Java • Git",
  expanded: false,
  imgName: "logo_enseeiht.png"
},
{
  id: 6,
  startDate: "2014-09-01",
  endDate: "2016-07-01",
  type: "edu",
  typeTitle: "Éducation",
  date: "2014-2016",
  mainTitle: "Classes préparatoires MPSI/MP",
  subtitle: "Formation intensive en Mathématiques, Physique et Sciences de l'ingénieur.",
  location: "Lorient",
  duration: "2 ans",
  contentBody: "",
  contentTechs: "",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 15,
  startDate: "2014-06-01",
  endDate: "",
  type: "edu",
  typeTitle: "Éducation",
  date: "2014",
  mainTitle: "Baccalauréat scientifique",
  subtitle: "Mention bien",
  location: "Ploërmel",
  duration: "",
  contentBody: "",
  contentTechs: "",
  expanded: false,
  imgName: ""
},
{
  id: 7,
  startDate: "2018-11-01",
  endDate: "2018-12-10",
  type: "proj",
  typeTitle: "Projet",
  date: "2018",
  mainTitle: "Machine Learning - Prix des habitations en Californie",
  subtitle: "Prédiction des prix de l'immobilier en Californie à partir d'un ensemble de données",
  location: "Birmingham",
  duration: "2 mois",
  contentBody: "",
  contentTechs: "Python • Numpy • Pandas • Scikit-learn",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 8,
  startDate: "2022-06-01",
  endDate: "2022-06-01",
  type: "cert",
  typeTitle: "Certificat",
  date: "2022",
  mainTitle: "3ème place à un concours de code individuel",
  subtitle: "Crédit Agricole / Isograd ”Dev'Week Crédit Agricole 2022”. Plus de 100 participants dans tout le groupe ainsi que venant  d'autres entreprises.",
  location: "Paris",
  duration: "2 heures",
  contentBody: "",
  contentTechs: "Python",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 9,
  startDate: "2021-12-01",
  endDate: "2022-04-01",
  type: "cert",
  typeTitle: "Certificat",
  date: "2022",
  mainTitle: "DataScientest : Programme data engineer",
  subtitle: "Formation sur différentes technologies bigdata.",
  location: "Paris",
  duration: "4 mois",
  contentBody: "",
  contentTechs: "ElasticSearch • Hadoop • Hive • Hbase • Kafka • Mongodb • Neo4j • Pyspark • Spark-streaming • SQL",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 10,
  startDate: "2021-10-01",
  endDate: "2021-11-01",
  type: "cert",
  typeTitle: "Certificat",
  date: "2021",
  mainTitle: "Dataiku certificates",
  subtitle: "Certificats dataiku \"advanced designer\" + \"ML Practitioner\"",
  location: "Paris",
  duration: "",
  contentBody: "",
  contentTechs: "",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 11,
  startDate: "2015-11-01",
  endDate: "2016-06-01",
  type: "proj",
  typeTitle: "Projet",
  date: "2015-2016",
  mainTitle: "Projet TIPE de prépa : Diagrammes de Voronoï",
  subtitle: "Implémentation d'un algorithme et extension du plan à la sphère",
  location: "Lorient",
  duration: "6 mois",
  contentBody: "Application du code pour trouver les zones de France les moins densément peuplées, à l'aide d'un ensemble de données des villes de France. Depuis, j'ai créé un site de démonstration de ce projet : https://voronoi.jlhervy.com/",
  contentTechs: "Python",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 12,
  startDate: "2017-10-01",
  endDate: "2018-01-20",
  type: "proj",
  typeTitle: "Projet",
  date: "2018",
  mainTitle: "Plusieurs travaux de traitement d'images",
  subtitle: "15 mini projets de traitement de données, notamment d'images.",
  location: "Toulouse",
  duration: "6 mois",
  contentBody: "ACP, Classification Bayésienne, Estimation de formes, Detection et comptage d'objets, segmentation par champs de Markov, restauration d'images",
  contentTechs: "Matlab",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 13,
  startDate: "2018-02-01",
  endDate: "2018-06-01",
  type: "proj",
  typeTitle: "Projet",
  date: "2018",
  mainTitle: "Exécution distribuée d'applications de type Map-Reduce",
  subtitle: "Développement d'un framework Hadoop ”from scratch”",
  location: "Toulouse",
  duration: "5 mois",
  contentBody: "Développement d'un système de fichiers de type HDFS, d'un namenode, d'un datanode. Développement de fonctionnalités avancées telle que la haute disponibilité.",
  contentTechs: "Java (Sockets, Semaphores, RMI) • Bash",
  expanded: false,
  imgName: ""
}
  ,
{
  id: 14,
  startDate: "2017-11-01",
  endDate: "2018-02-01",
  type: "proj",
  typeTitle: "Projet",
  date: "2017",
  mainTitle: "Casse-briques multijoueur",
  subtitle: "Développement d'un jeu vidéo en utilisant la méthode agile Scrum",
  location: "Toulouse",
  duration: "3 mois",
  contentBody: "",
  contentTechs: "Java (Slick2D) • Trello",
  expanded: false,
  imgName: ""
},
{
  id: 16,
  startDate: "2022-10-15",
  endDate: "2022-10-16",
  type: "proj",
  typeTitle: "Projet",
  date: "2022",
  mainTitle: "Développement de 2 sites : (cv|voronoi).jlhervy.com",
  subtitle: "Hébergement sur Google Cloud.",
  location: "Paris",
  duration: "",
  contentBody: "",
  contentTechs: "Javascript • React • Python • Cloud • GCP",
  expanded: false,
  imgName: ""
},
{
  id: 17,
  startDate: "2022-10-20",
  endDate: "2022-10-21",
  type: "cert",
  typeTitle: "Certificat",
  date: "2022",
  mainTitle: "Formation sur Google Cloud (Certificat Coursera)",
  subtitle: "\"Google Cloud Fundamentals: Core Infrastructure\"",
  location: "Paris",
  duration: "",
  contentBody: "",
  contentTechs: "",
  expanded: false,
  imgName: ""
}
]

export default data;