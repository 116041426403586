import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import "./skills.scss"
import dataFr from './dataFr';
import dataEn from './dataEn';
import cvDataFr from "../curriculum-vitae/dataFr"
import cvDataEn from "../curriculum-vitae/dataEn"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const firstTwoHighlight = (text) => {
  return <><span className="text-highlight">{text.substring(0, 2)}</span>{text.substring(2)}</>
}



const Skills = (props) => {
  const [t, _] = useTranslation();
  const [data, setData] = useState(dataFr)
  const [cvData, setCvData] = useState(cvDataFr)

  const computeDistinctTechs = (cv) => {
    const uniqueSet = new Set();

    for (const obj of cv) {
      if (obj.hasOwnProperty('contentTechs') && obj.contentTechs !== "") {
        const techs = obj.contentTechs.split('•');
        techs.forEach(tech => uniqueSet.add(tech.trim()));
      }
    }

    return Array.from(uniqueSet);
  }

  const cachedTechs = useMemo(() => computeDistinctTechs(cvData), [cvData])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t('tooltip-technologies')}
  
    </Tooltip>
  );

  useEffect(() => {
    if (props.language === "fr") {
      setData(dataFr)
      setCvData(cvDataFr)
    } else {
      setData(dataEn)
      setCvData(cvDataEn)
    }
  }, [props.language])

  return (
    <Container className="mt-3 opacity-container">
      <Row>
        <Col lg={1} />
        <Col>
          <h2><i className="fa-solid fa-language text-highlight"></i> {firstTwoHighlight(t('skills-languages'))} </h2>
          <ul>
            {data["langages"].map(el => <li key={el}>{el}</li>)}
          </ul>


          <h2><i className="fa-solid fa-fire text-highlight"></i> {firstTwoHighlight(t('skills-passions'))} </h2>
          <ul>
            {data["passions"].map(el => {
              if (el.includes("piano")) {
                return <li key={el}>{el}</li>
              } else { return <li key={el}>{el}</li> }
            })}
          </ul>
        </Col>
        <Col>
          <h2 ><i className="fa-solid fa-badge-check text-highlight"></i> {firstTwoHighlight(t('skills-qualities'))}</h2>

          <ul>
            {data["qualities"].map(el => <li key={el}>{el}</li>)}

          </ul>


        </Col>

      </Row>
      <Row>
        <Col lg={1} />
        <Col>
          <h2><i className="fa-solid fa-microchip text-highlight"></i> {firstTwoHighlight(t('skills-techs'))}  <OverlayTrigger
                placement="top"
                delay={{ show: 50, hide: 150 }}
                overlay={renderTooltip}
              >
                <i className="fa-solid fa-circle-info fa-xs my-auto" ></i>
              </OverlayTrigger></h2>
          <ul>
            <li>
              {cachedTechs.join(" | ")}
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
};


export default Skills;