import './App.scss';
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { LinkContainer } from 'react-router-bootstrap'
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './components/home/home';
import About from './components/about/about';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import CurriculumVitae from './components/curriculum-vitae/curriculum-vitae';
import Skills from './components/skills/skills';
import FrenchFlag from './locales/fr/french-flag.svg'
import UkFlag from './locales/en/FlagUk.svg'
import Image from 'react-bootstrap/Image'


const App = () => {
  const [darkMode, setDarkMode] = React.useState(false);
  const [language, setLanguage] = React.useState("fr");
  const { t, i18n } = useTranslation();

  return (
    <Container fluid className={'p-0 theme ' + (darkMode ? 'theme--dark' : 'theme--default')}>
      <div className="main-container">
        <Navbar className="customNavBar" expand="lg">
          <Container>
            <Image className="pe-2" style={{ height: '1.4em' }} src={darkMode ? './jl_ico_light.svg' :'./jl_ico_dark.svg'}></Image>
            <div href="#home" className="tw-text-xl">{t('topNav-title')}</div>
            <div className="me-auto">
            </div>
            <ToggleButtonGroup className="me-3" type="radio" name="languages" value={language} onChange={(ev) => { setLanguage(ev); i18n.changeLanguage(ev); }}>
              <ToggleButton id="tbg-btn-1" value={"fr"} variant="secondary">
                <Row> <Col>Fr <Image className="pb-1 ps-1" style={{ height: '1.2em' }} src={FrenchFlag}></Image></Col></Row>
              </ToggleButton>
              <ToggleButton id="tbg-btn-2" value={"en"} variant="secondary">
                <Row> <Col>En <Image className="pb-1 ps-1" style={{ height: '1.2em' }} src={UkFlag}></Image></Col></Row>
              </ToggleButton>
            </ToggleButtonGroup>

            <Form className="d-flex">
              <Form.Check
                type="switch"
                label=""
                id="darkmodeswitch"
                onClick={() => {
                  setDarkMode(!darkMode);
                }}
              />
              <div>{darkMode ? <i className="fa-solid fa-moon fa-lg"></i> : <i className="fa-solid fa-sun-bright fa-lg"></i>}</div>

            </Form>

          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col lg={2}>
              <ListGroup variant="flush" className="mt-3 shadow" id="navigation-left">
                <LinkContainer to="/">
                  <ListGroup.Item action active={false}>{t('leftNav-home')} </ListGroup.Item>
                </LinkContainer>
                <LinkContainer to="/curriculum-vitae">
                  <ListGroup.Item action active={false}> {t('leftNav-cv')}</ListGroup.Item>
                </LinkContainer>
                <LinkContainer to="/skills">
                  <ListGroup.Item action active={false}> {t('leftNav-skills')}</ListGroup.Item>
                </LinkContainer>
                <LinkContainer to="/about">
                  <ListGroup.Item action active={false}>{t('leftNav-about')}</ListGroup.Item>
                </LinkContainer>
              </ListGroup>

            </Col>
            <Col lg={9} xl={8}>
              <Routes>
                <Route path="/" element={<Home />}>
                  <Route index element={<Home />} />
                  <Route path="home" element={<Home />} />
                </Route>
                <Route path="/curriculum-vitae" element={<CurriculumVitae language={language} darkMode={darkMode} />} />
                <Route path="/about" element={<About language={language} darkMode={darkMode} />} />
                <Route path="/skills" element={<Skills language={language} darkMode={darkMode} />} />
              </Routes>
              <br></br>
            </Col>
            <Col lg={1} xl={2}></Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default App;