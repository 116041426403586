import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {highlightText} from '../utils';

const AccordionItemContent = (props) => {

  const listSeparatedItems = props.contentBody.split("|")

  return (
    <Container className="p-0 m-0">
      {listSeparatedItems.map(item => <Row key={item} className="my-2"><Col xs="auto" className="p-0"><i className="fa-solid fa-minus fa-xs"></i></Col><Col> {highlightText(item, props.textFilter)}</Col></Row>)}
    </Container>
  )
}

export default AccordionItemContent;