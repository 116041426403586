import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import "./home.scss"
import { useTranslation } from 'react-i18next';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ButtonCopy = (props) => {
  return (
    <Button
      onClick={() => { props.setShow(true); navigator.clipboard.writeText(props.text) }}
      variant="secondary" size="sm" className="ms-2" >
      <i className="fa-solid fa-copy"></i>
    </Button>
  )
}

const Home = () => {
  const [t, _] = useTranslation();
  const [showCopyNumber, setShowCopyNumber] = useState(false);
  const [showCopyMail, setShowCopyMail] = useState(false);
  return (
    <Container className="mt-3">

      <Row>
        {/* <Col lg={3}><Image fluid src={me}></Image></Col> */}
        <Col />
        <Col md="auto" lg={9}>
          <Row>
            <h1>Jean-Luc HERVY, Data Engineer</h1>
          </Row>
          <Row className="mb-5">
            <Col className="lead">{t('home-subtitle')}</Col>
          </Row>
          {/* <Row className="mb-5">
            <Col className="xl-3"></Col>
            <Col className="xl-3">
              <Image className="pe-2" src={'./jlconcours.png'}></Image>
            </Col>
          </Row> */}


          <ListGroup variant="flush">
            <ListGroup.Item>
              <Col ><i className="fa-solid fa-envelope fa-lg me-2"></i> jlhervy@outlook.com <ButtonCopy setShow={setShowCopyMail} text={"jlhervy@outlook.com"} /><div
                aria-live="polite"
                aria-atomic="true"
              >
                <ToastContainer style={{ width: '7em' }} position="top-center">
                  <Toast onClose={() => setShowCopyMail(false)} show={showCopyMail} delay={800} autohide>
                    <Toast.Body>Copié !</Toast.Body>
                  </Toast>
                </ToastContainer>
              </div></Col>
            </ListGroup.Item>
            <ListGroup.Item><Col><i className="fa-solid fa-globe fa-lg me-2"></i> {t('home-website')}<Button size="sm" target="_blank" rel="noopener noreferrer" href="https://voronoi.jlhervy.com" className="mx-2"> {t('home-voronoi')}</Button></Col></ListGroup.Item>
            <ListGroup.Item><Col ><i className="fa-brands fa-linkedin fa-lg me-2"></i> <Button size="sm" href="https://www.linkedin.com/in/jean-luc-hervy/" target="_blank" rel="noopener noreferrer">/in/jean-luc-hervy/</Button></Col></ListGroup.Item>
            <ListGroup.Item><Col ><i className="fa-solid fa-location-dot fa-xl me-2"></i> Paris, France</Col></ListGroup.Item>

          </ListGroup>
        </Col>
        <Col />
      </Row>

      <Row className="mt-2">
        <Col />
        <Col xs="auto">
          <LinkContainer to="/curriculum-vitae">
            <Button variant="danger">{t('home-goCV')}</Button>
          </LinkContainer>
        </Col>
        <Col />
      </Row>
      {/* <Row><Col><strong>CV format jpeg : </strong></Col></Row>
      <Row className="mt-2"><Col lg={3}><a href={cv} target="_blank" rel="noopener noreferrer" >
        <Image fluid src={cv}></Image>
      </a></Col><Col /></Row> */}




      {/* <Row className="justify-content-md-center">

        <Col md="auto">
          <LinkContainer to="/concepts">
            <Button variant="dark">{t('home-startDefinitions')}</Button>
          </LinkContainer>
        </Col>
      </Row> */}

    </Container>
  )
};

export default Home;