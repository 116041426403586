import React, { useRef, useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import "./curriculum-vitae.scss"
import Controls from "./components/controls"
import dataFr from './dataFr';
import dataEn from './dataEn';
import { useReactToPrint } from "react-to-print";
import { checkWetherShouldAppear } from './utils';
import CustomAccordion from './components/customAccordion';

const firstTwoHighlight = (text) => {
  return <><span className="text-highlight">{text.substring(0, 2)}</span>{text.substring(2)}</>
}

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}


const CurriculumVitae = (props) => {
  const [t, _] = useTranslation();
  const componentRef = useRef(null);

  const [formSort, setFormSort] = useState(true)
  const [formCheckLockclick, setFormCheckLockclick] = useState(false)
  const [formCheckGroupdomain, setFormCheckGroupdomain] = useState(true)
  const [blocks, setBlocks] = useState(dataFr)
  const [textFilter, setTextFilter] = useState("")
  const [animateLock, setAnimateLock] = useState(false)

  const [blocksGlobal, setBlocksGlobal] = useState(dataFr)
  const [blocksWork, setBlocksWork] = useState(dataFr)
  const [blocksEducation, setBlocksEducation] = useState(dataFr)
  const [blocksProjects, setBlocksProjects] = useState(dataFr)
  const [blocksCertificats, setBlocksCertificats] = useState(dataFr)

  const [nbBlocks, setNbBlocks] = useState(0)

  const { width } = useViewport();
  const breakpoint = 768;

  useEffect(() => {
    if (props.language === "fr") {
      setBlocks(dataFr)
    } else {
      setBlocks(dataEn)
    }
  }, [props.language])

  const sort = useCallback((a, b) => { let res = formSort ? b.startDate.localeCompare(a.startDate) : a.startDate.localeCompare(b.startDate); return res; }, [formSort])

  useEffect(() => {
    if (!formCheckGroupdomain) {
      setBlocksGlobal(blocks.filter(item => checkWetherShouldAppear(item, textFilter)).sort(sort));
      setNbBlocks(blocksGlobal.length);
    } else {
      setBlocksWork(blocks.filter(item => item.type === "work").filter(item => checkWetherShouldAppear(item, textFilter)).sort(sort))
      setBlocksEducation(blocks.filter(item => item.type === "edu").filter(item => checkWetherShouldAppear(item, textFilter)).sort(sort))
      setBlocksProjects(blocks.filter(item => item.type === "proj").filter(item => checkWetherShouldAppear(item, textFilter)).sort(sort))
      setBlocksCertificats(blocks.filter(item => item.type === "cert").filter(item => checkWetherShouldAppear(item, textFilter)).sort(sort))
      setNbBlocks(blocksWork.length + blocksCertificats.length + blocksEducation.length + blocksProjects.length)

    }
  }, [blocks, textFilter, formCheckGroupdomain, sort, blocksGlobal.length, blocksWork.length, blocksCertificats.length, blocksEducation.length, blocksProjects.length])

  // useEffect(() => {
  //   // setNbBlocks(blocksGlobal.length);
  //   console.log("used effect")
  // }, [blocksGlobal.length])

  const toggle = (id) => {
    const prevState = [...blocks]
    const index = prevState.findIndex(item => item.id === id);
    prevState[index].expanded = !prevState[index].expanded;
    setBlocks(prevState);
  }

  const toggleExpand = (expand) => {
    const prevState = [...blocks]
    const newBlocks = prevState.map(item => {
      item.expanded = expand;
      return item;
    });
    setBlocks(newBlocks);
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "cv-jean-luc-hervy",
    removeAfterPrint: true
  });

  const getCustomAccordion = (item, mobile) => (<CustomAccordion key={item.id} formCheckLockclick={formCheckLockclick} eventKey={item.id} item={item} textFilter={textFilter} expand={item.expanded} mobile={mobile} onClick={() => {
    if (!formCheckLockclick) {
      toggle(item.id)
    } else {
      setAnimateLock(true)
    }
  }} />)

  return (
    <Container className="mt-3 opacity-container p-0">
      {(width > breakpoint) ? 
      <>
      <Controls handlePrint={handlePrint} formCheckLockclick={formCheckLockclick} formCheckGroupdomain={formCheckGroupdomain} setFormCheckLockclick={setFormCheckLockclick} setFormCheckGroupdomain={setFormCheckGroupdomain} toggleExpand={toggleExpand} setTextFilter={setTextFilter} formSort={formSort} setFormSort={setFormSort} animateLock={animateLock} setAnimateLock={setAnimateLock} nbBlocks={nbBlocks} />
      <Row ref={componentRef}>
        {(!formCheckGroupdomain && blocksGlobal) ?
          <Accordion>
            {
              blocksGlobal.map(item => (
                getCustomAccordion(item)
              ))
            }
          </Accordion>
          : (blocksCertificats && blocksEducation && blocksProjects && blocksWork) ?
            <Accordion>

              <div> <h2><i className="fa-solid fa-briefcase mb-1 text-highlight"></i> {firstTwoHighlight(t('cv-travail'))}</h2> {blocksWork.map(item => (
                getCustomAccordion(item)
              ))}</div>
              <div> <h2><i className="fa-solid fa-graduation-cap mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-education'))}</h2> {blocksEducation.map(item => (
                getCustomAccordion(item)
              ))}</div>
              <div> <h2><i className="fa-solid fa-gears mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-projets'))}</h2> {blocksProjects.map(item => (
                getCustomAccordion(item)
              ))}</div>
              <div> <h2> <i className="fa-solid fa-certificate mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-certificats'))}</h2> {blocksCertificats.map(item => (
                getCustomAccordion(item)
              ))}</div>
            </Accordion> : <></>

        }
      </Row>
    </> : 
    <>
    <Controls handlePrint={handlePrint} formCheckLockclick={formCheckLockclick} formCheckGroupdomain={formCheckGroupdomain} setFormCheckLockclick={setFormCheckLockclick} setFormCheckGroupdomain={setFormCheckGroupdomain} toggleExpand={toggleExpand} setTextFilter={setTextFilter} formSort={formSort} setFormSort={setFormSort} animateLock={animateLock} setAnimateLock={setAnimateLock} nbBlocks={nbBlocks} mobile={true}/>
    <Row ref={componentRef}>
      {(!formCheckGroupdomain && blocksGlobal) ?
        <Accordion>
          {
            blocksGlobal.map(item => (
              getCustomAccordion(item, true)
            ))
          }
        </Accordion>
        : (blocksCertificats && blocksEducation && blocksProjects && blocksWork) ?
          <Accordion>

            <div> <h2><i className="fa-solid fa-briefcase mb-1 text-highlight"></i> {firstTwoHighlight(t('cv-travail'))}</h2> {blocksWork.map(item => (
              getCustomAccordion(item, true)
            ))}</div>
            <div> <h2><i className="fa-solid fa-graduation-cap mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-education'))}</h2> {blocksEducation.map(item => (
              getCustomAccordion(item, true)
            ))}</div>
            <div> <h2><i className="fa-solid fa-gears mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-projets'))}</h2> {blocksProjects.map(item => (
              getCustomAccordion(item, true)
            ))}</div>
            <div> <h2> <i className="fa-solid fa-certificate mb-1  text-highlight"></i> {firstTwoHighlight(t('cv-certificats'))}</h2> {blocksCertificats.map(item => (
              getCustomAccordion(item, true)
            ))}</div>
          </Accordion> : <></>

      }
    </Row>
  </> }
  <div className="tw-h-[20vh]"></div>
    </Container>
  )
};


export default CurriculumVitae;