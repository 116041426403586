const highlightText = (text, textFilter) => {
    let mask = new Array(text.length).fill(0);
    let textLower = text.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
    let textFilterLower = textFilter.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")

    const filters = textFilterLower.split(" ")

    for (const filter of filters) {
        let pos = 0
        if (filter.length > 2) {
            while (true) {
                pos = textLower.indexOf(filter, pos)
                if (pos >= 0) {
                    let newHighlights = new Array(filter.length).fill(1)
                    mask.splice(pos, filter.length, ...newHighlights)
                    pos += filter.length
                } else break;
            }
        }
    }
    return constructHighlightFromMaskAndText(mask, text)
}

const constructHighlightFromMaskAndText = (mask, text) => {

    let highlightedText = []

    let currentValue = mask[0]
    let beginIdx = 0

    for (const idx in mask) {
        const value = mask[idx]
        if (value !== currentValue) {
            if (currentValue) {
                highlightedText.push(<span className="text-search-highlight">{text.substring(beginIdx, idx)}</span>)
            } else {
                highlightedText.push(text.substring(beginIdx, idx))
            }
            beginIdx = idx
            currentValue = value
        }
    }
    if (currentValue) {
        highlightedText.push(<span className="text-search-highlight">{text.substring(beginIdx, text.length)}</span>)
    } else {
        highlightedText.push(text.substring(beginIdx, text.length))
    }

    return <span>{highlightedText.map(el => el)}</span>
}

const searchFields = ["typeTitle","date","mainTitle","subtitle","location","contentBody","contentTechs"]

const checkWetherShouldAppear = (item, textFilter) => {
    let shouldAppear = false;
    let anyLonger2 = false;
    const filters = textFilter.split(" ")
    for (const filter of filters) {
      if (shouldAppear) {
        break;
      }
      if (filter.length > 2) {
        anyLonger2 = true;
        let textFilterLower = filter.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
        for (const field of searchFields) {
          if (String(item[field]).toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(textFilterLower)) {
            shouldAppear = true;
            break;
          }
        }
      }
    }
    if (!anyLonger2) {
      shouldAppear = true;
    }
    return shouldAppear;
  }

export {highlightText, checkWetherShouldAppear};