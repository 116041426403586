import { ReactComponent as YourSvgLight } from './archi_en.svg';
import { ReactComponent as YourSvgDark } from './archi_en_dark.svg';
import { ReactComponent as YourSvgLightFR } from './archi_fr.svg';
import { ReactComponent as YourSvgDarkFR } from './archi_fr_dark.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

const About = (props) => {
  const [t, _] = useTranslation();

  return (
    <Container className="mt-3 opacity-container">
      <div className="navbar-nav">
        <Row className="justify-content-md-center"><Col md="auto" className="my-3"><h2>{t('about-title')}</h2></Col></Row>
        <Row  className="opacity2 justify-content-md-center">
          {t('about-content')}
        </Row>
        <Row>
          {props.language === "en" && props.darkMode ? <YourSvgDark className="my-3" /> : props.language === "en" && !props.darkMode ? <YourSvgLight className="my-3" /> : props.language === "fr" && props.darkMode ? <YourSvgDarkFR className="my-3" /> : <YourSvgLightFR className="my-3" />}

        </Row>
      </div>
    </Container>
  )
};


export default About;