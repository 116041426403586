let data = {
    techs: {
        langages: ['JAVA',
            'Scala',
            'Python',
            'Bash',
            'SQL',
            'C'],
        web: ["HTML/CSS",
            "Javascript",
            "AngularJS/Vue/React",
            "Django/Flask/Fastapi..."],
        bigdata: ["Spark",
            "Hadoop",
            "MapReduce",
            "Hbase",
            "Kafka",
            "Hive",
            "Cloudera",
            "ElasticSearch",
            "Neo4j"],
        others: ["Docker",
            "Git",
            "Unix",
            "Teradata",
            "Google Cloud"],
        dataScience: ["Deep Learning",
            "Vision par ordinateur",
            "Analyse prédictive",
            "Tensorflow/Pytorch",
            "Scikit-learn"],
    },
    langages: [
        "Anglais : Bon niveau à l'oral, très bon niveau à l'écrit. (TOEIC 985/990)",
        "Français : Natif"
    ],
    qualities: [
        'Autonome',
        'Proactif',
        'Efficace',
        'Goût du challenge',
        "Goût de l'amélioration et du changement",
        '"Bias for action"'
    ],
    passions: [
        "Musique, piano",
        "Sport (natation, course à pied)",
        "Échecs",
        "Lecture",
        "Sciences",
        "Technologies"
    ]
}

export default data;