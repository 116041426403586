import React, { useRef } from 'react';
import {
    motion,
    useSpring,
    useScroll,
    useTransform,
} from "framer-motion";
interface DisappearingBrickProps {
    size: string;
    classes?:string;
}

const DisappearingBrick: React.FC<DisappearingBrickProps> = ({ size, classes }) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start 99%", "center 40%"],
    });
    const damping = 100;
    const stiffness = 300;
    const springParams = { damping: damping, stiffness: stiffness };

    // const scaleY = useSpring(useTransform(scrollYProgress, [0, 1], [1, 0]), springParams);
    const height = useSpring(useTransform(scrollYProgress, [0, 1], [size, "0"]), springParams);
    const heightvh = useTransform(height, (value) => `${value}vh`);

    return (
        <motion.div ref={ref}
            style={{
                // scaleY: scaleY,
                height: heightvh,
                overflow: "hidden",
            }} className={classes}>

        </motion.div>
    );
};

export default DisappearingBrick;