import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { highlightText } from '../utils';

const getIconFromType = (type, typeTitle, textFilter) => {
  let retour;

  if (type === "work") {
    retour = <span><i className="fa-solid fa-briefcase"></i> {highlightText(typeTitle, textFilter)} </span>
  } else if (type === "edu") {
    retour = <span><i className="fa-solid fa-graduation-cap"></i> {highlightText(typeTitle, textFilter)} </span>
  } else if (type === "proj") {
    retour = <span><i className="fa-solid fa-gears"></i> {highlightText(typeTitle, textFilter)} </span>
  } else if (type === "cert") {
    retour = <span><i className="fa-solid fa-certificate"></i> {highlightText(typeTitle, textFilter)} </span>
  }
  return retour;
}




const AccordionItemTitle = (props) => {
  return (
    (props.mobile) ?
      <Container className="p-0 m-0" style={{ cursor: (props.item.contentBody !== "" && !props.formCheckLockclick) ? 'pointer' : 'default' }}>
        <Row className="d-flex">
          <Col xs={"auto"} className="pe-0 me-0"><>{highlightText(props.item.date, props.textFilter)}</></Col>
          <Col xs={"auto"} className="pe-0 flex-fill">{props.item.duration ? <><i className="fa-regular fa-clock"></i> <>{props.item.duration}</></> : null}</Col>
          <Col xs={"auto"}><i className="fa-regular fa-location-dot pe-0 me-0"></i> {highlightText(props.item.location, props.textFilter)}</Col>
        </Row>

        {props.item.imgName === "" ?
          <Row>
            <Col>
              <Row><Col><strong>{highlightText(props.item.mainTitle, props.textFilter)}</strong> {props.item.contentBody !== "" ? <i className="fa-solid fa-chevron-down"></i> : null}</Col></Row>
              <Row><Col>{highlightText(props.item.subtitle, props.textFilter)}</Col></Row>

            </Col>
          </Row>
          :
          <>
            <Row><Col><strong>{highlightText(props.item.mainTitle, props.textFilter)}</strong> {props.item.contentBody !== "" ? <i className="fa-solid fa-chevron-down"></i> : null}</Col></Row>
            <Row>
              <Col xs={3} md={1} className="pe-1 pe-md-0 ps-md-1 my-auto"><Image className="iconCurriculum" fluid src={"imgs/" + props.item.imgName}></Image> </Col>
              <Col xs={9} md={7} className="">
                <Row><Col>{highlightText(props.item.subtitle, props.textFilter)}</Col></Row>

              </Col>
            </Row>
          </>}



      </Container>
      :
      <Container className="p-0 m-0" style={{ cursor: (props.item.contentBody !== "" && !props.formCheckLockclick) ? 'pointer' : 'default' }}>
        <Row>
          <Col xs={12} md={2} lg={2} className="pe-0 me-0">
            <Row><Col xs={"auto"} md={12} className="pe-0 me-0"><strong>{highlightText(props.item.date, props.textFilter)}</strong></Col>
              <Col className="pe-0 me-0">{props.item.duration ? <><i className="fa-regular fa-clock"></i> <strong>{props.item.duration}</strong></> : null}</Col></Row>
          </Col>
          {props.item.imgName === "" ?
            <Col md={8} lg={8}>
              <Row><Col><strong>{highlightText(props.item.mainTitle, props.textFilter)}</strong> {props.item.contentBody !== "" ? <i className="fa-solid fa-chevron-down"></i> : null}</Col></Row>
              <Row><Col>{highlightText(props.item.subtitle, props.textFilter)}</Col></Row>

            </Col>
            :
            <>
              <Col xs={3} md={1} className="pe-1 pe-md-0 ps-md-1 my-auto"><Image className="iconCurriculum" fluid src={"imgs/" + props.item.imgName}></Image> </Col>
              <Col xs={9} md={7} className="">

                <Row><Col><strong>{highlightText(props.item.mainTitle, props.textFilter)}</strong> {props.item.contentBody !== "" ? <i className="fa-solid fa-chevron-down"></i> : null}</Col></Row>
                <Row><Col>{highlightText(props.item.subtitle, props.textFilter)}</Col></Row>

              </Col>
            </>}
          <Col md={2} lg={2} className="ps-md-0 pe-md-1 ps-lg-2">
            <Row><Col xs="auto" md={12} className="pe-0 me-0">{getIconFromType(props.item.type, props.item.typeTitle, props.textFilter)}</Col>
              <Col><i className="fa-solid fa-location-dot pe-0 me-0"></i> {highlightText(props.item.location, props.textFilter)}</Col></Row>
          </Col>
        </Row>

      </Container>
  )
}

export default AccordionItemTitle;